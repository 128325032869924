import React from "react";
import { Layout } from "../../components/Layout";
import community1 from "../../images/community-1.jpeg";
import community2 from "../../images/community-2.jpeg";
import community3 from "../../images/community-3.jpeg";
import community4 from "../../images/community-4.jpeg";
import community5 from "../../images/community-5.jpeg";
import community6 from "../../images/community-6.jpeg";

const CommunityOutreachPage = () => {
  return (
    <Layout>
      <main className="relative bg-white z-0">
        <img
          src={community4}
          alt=""
          className="w-full h-[36rem] md:h-[48rem] object-cover z-0 absolute"
        />
        <div className="w-full h-[36rem] md:h-[48rem] z-10 bg-gray-800 absolute mix-blend-multiply opacity-60" />
        <div className="w-full h-[36rem] md:h-[48rem] z-20 relative">
          <div className="absolute w-full pt-28 px-16 flex flex-col items-center justify-center">
            <h1 className="text-white mt-32 md:mt-64 text-5xl font-serif">
              Community Outreach
            </h1>
            <h2 className="text-white text-xl md:text-2xl mt-2">
              Bringing the love of Jesus and the words of Jesus to our
              community.
            </h2>
          </div>
        </div>
        <div className="flex flex-col items-center">
          <div className="mx-12 my-8 max-w-xl text-left text-cyan-900 text-2xl">
            Our desire is that every man, woman, and child in Alliance and
            surrounding areas would have the opportunity to respond to the good
            news of Jesus Christ on repeated occasion. We do this through a
            number of community outreaches throughout the year. Check out our{" "}
            <a href="/events" className="text-blue-500 hover:underline">
              event calendar
            </a>{" "}
            for upcoming community outreach opportunities planned.
          </div>
        </div>
        <div className="grid grid-flow-row items-end grid-cols-1 md:grid-cols-3 gap-8 p-8">
          <ImageWithCaption
            img={community1}
            caption="Bringing Christmas joy to those in nursing homes"
          />
          <ImageWithCaption
            img={community2}
            caption="Hayrides at the Fall Festival"
            className="md:mb-28"
          />
          <ImageWithCaption
            img={community3}
            caption="Face painting at the Fall festival"
            className="md:mb-12"
          />
          <ImageWithCaption
            img={community5}
            caption="Fundraiser for a mission trip for AHS students"
            className="md:mt-12"
          />
          <ImageWithCaption
            img={community6}
            caption="Serving water and snacks to participants in the Carnation parade"
            className="md:mb-16"
          />
        </div>
      </main>
    </Layout>
  );
};

function ImageWithCaption({ img, caption, className }) {
  return (
    <div className={`flex flex-col items-center gap-4 ${className ?? ""}`}>
      <img src={img} className="w-full object-cover shadow-xl rounded-3xl" />
      <div className="text-slate-500 text-md px-4 text-center">{caption}</div>
    </div>
  );
}

export default CommunityOutreachPage;
